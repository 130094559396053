import * as React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import { useGraphQL } from '../../hooks/use-graphql';
import {
  Layout,
  SEO,
  Hero,
  ImageWithText,
  Contact,
  Services,
  OurClients,
  Map,
  GoOverYourProject,
  WhyWorkWithUs,
  SuitsOverlay,
} from '../../components';
import { useServices } from '../../data';

function GameRoomDesignPage() {
  const title = 'Gaming Room Design';

  const services = useServices().filter((s) => s.title !== title);

  const service = useServices().filter((s) => s.title === title);

  const Icon = service[0].icon;

  const { hero } = useStaticQuery(graphql`
    {
      hero: file(relativePath: { eq: "game-room-design-hero.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);
  const { gamingRoomDesignImage1 } = useGraphQL();

  return (
    <Layout>
      <SEO title="Gaming Room Design" />
      <Hero
        image={hero.childImageSharp.fluid}
        heading={
          <>
            <Icon className="mx-auto text-white w-28 h-28" />
            <span className="inline-block mt-8">{title}</span>
          </>
        }
        hasGradient
      />
      <SuitsOverlay />
      <ImageWithText
        marginTop
        image={gamingRoomDesignImage1.childImageSharp.fluid}
        heading="Designed to Suit Your Venue"
        copy={service[0].description}
      />
      <GoOverYourProject />
      <WhyWorkWithUs />
      <Services services={services} />
      <OurClients />
      <Contact />
      <Map />
    </Layout>
  );
}

export default GameRoomDesignPage;
